import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as SelectMUI
} from '@mui/material'
import { Control, Controller, FieldError } from 'react-hook-form'
import { Option } from '../../types/Option'

interface SelectProps {
  name: string
  label: string
  className?: string
  options: Option[]
  defaultOption?: Option
  control: Control<any>
  errorContent?: FieldError
}

const SelectBase = ({
  name,
  label,
  className = 'mt-3',
  options,
  defaultOption,
  control,
  errorContent
}: SelectProps) => {
  const labelId = `${label}-label`
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl fullWidth error={!!errorContent}>
          <InputLabel id={labelId} className={className}>
            {label}
          </InputLabel>
          <SelectMUI
            {...field}
            labelId={labelId}
            id={labelId}
            label={label}
            value={(field?.value || defaultOption?.value) ?? ''}
            className={className}
          >
            {defaultOption && (
              <MenuItem value={defaultOption.value}>
                {defaultOption.label}
              </MenuItem>
            )}
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </SelectMUI>
          {errorContent && (
            <FormHelperText>{errorContent?.message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  )
}
export default SelectBase
